import {config} from 'blocks/cases/cases.config'
import {createInsightsMiddleware} from 'instantsearch.js/es/middlewares'
import {useInstantSearch} from 'react-instantsearch-hooks-web'
import {useLayoutEffect} from 'react'

export const InsightsMiddleware: React.FC = () => {
	const {use} = useInstantSearch()

	useLayoutEffect(() => {
		const configure = async () => {
			const [{createInsightsMiddleware}, {default: aa}] = await Promise.all([
				import('instantsearch.js/es/middlewares'),
				import('search-insights')
			])

			aa('init', {
				appId: 'APPLICATION_ID',
				apiKey: 'SEARCH_API_KEY',
				userToken: `random${Math.round(Math.random() * 100000)}`
			})
			;(window as any).aa = aa

			const middleware = createInsightsMiddleware({
				insightsClient: aa,
				insightsInitParams: {
					useCookie: false
				}
			})
			use(middleware)
		}

		configure()
	}, [use])

	return null
}
