import * as icons from './icons'
import React, { HTMLProps } from 'react'
import { fromModule } from 'util/styler/Styler'
import css from './Icon.module.scss'

export type IconKey = keyof typeof icons

const styles = fromModule(css)

export const Icon: React.FC<{
    icon: IconKey
    inline?: boolean
} & HTMLProps<HTMLDivElement>> = ({ icon, inline, ...rest }) => {
    if (!icons[icon]) {
        console.warn(`Undefined icon key: ${icon}`)
        return null
    }

    const Component = icons[icon].default
    return (
        <div {...rest} className={styles.icon.mergeProps(rest).mod({ inline })()}>
            <Component />
        </div>
    )
}
