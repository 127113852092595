import '@algolia/autocomplete-theme-classic'

import {AutocompleteOptions, autocomplete} from '@algolia/autocomplete-js'
import {
	Fragment,
	createElement,
	useEffect,
	useMemo,
	useRef,
	useState
} from 'react'
import {usePagination, useSearchBox} from 'react-instantsearch-hooks'

import {BaseItem} from '@algolia/autocomplete-core'
import React from 'react'
import {createLocalStorageRecentSearchesPlugin} from '@algolia/autocomplete-plugin-recent-searches'
import {createQuerySuggestionsPlugin} from '@algolia/autocomplete-plugin-query-suggestions'
import {render} from 'react-dom'
import {useApp} from 'AppContext'

type SetInstantSearchUiStateOptions = {
	query: string
}

export const Autocomplete: React.FC<
	Partial<AutocompleteOptions<BaseItem>> & {
		onSearch?: (query: string) => void
		className?: string
	}
> = ({className, onSearch, ...autocompleteProps}) => {
	const autocompleteContainer = useRef<HTMLDivElement>(null)

	const {searchClient} = useApp()
	const {query, refine: setQuery} = useSearchBox()
	const {refine: setPage} = usePagination()
	const [instantSearchUiState, setInstantSearchUiState] =
		useState<SetInstantSearchUiStateOptions>({query})

	useEffect(() => {
		setQuery(instantSearchUiState.query)
		setPage(0)
	}, [instantSearchUiState.query])

	const plugins = useMemo(() => {
		const recentSearches = createLocalStorageRecentSearchesPlugin({
			key: 'instantsearch',
			limit: 3,
			transformSource({source}) {
				return {
					...source,
					onSelect({item}) {
						setInstantSearchUiState({query: item.label})
					}
				}
			}
		})

		const querySuggestions = createQuerySuggestionsPlugin({
			searchClient,
			indexName: 'pages_query_suggestions',
			getSearchParams() {
				return recentSearches.data!.getAlgoliaSearchParams({
					hitsPerPage: 6
				})
			},
			transformSource({source}) {
				return {
					...source,
					sourceId: 'querySuggestionsPlugin',
					onSelect({item}) {
						setInstantSearchUiState({query: item.query})
					},
					getItems(params) {
						if (!params.state.query) {
							return []
						}

						return source.getItems(params)
					}
				}
			}
		})

		return [recentSearches, querySuggestions]
	}, [])

	useEffect(() => {
		if (!autocompleteContainer.current) {
			return
		}

		const autocompleteInstance = autocomplete({
			...autocompleteProps,
			container: autocompleteContainer.current,
			initialState: {query},
			onReset() {
				setInstantSearchUiState({query: ''})
			},
			onSubmit({state}) {
				setInstantSearchUiState({query: state.query})
				console.log('submit', {state})
				if (onSearch) onSearch(state.query)
			},
			onStateChange({prevState, state}) {
				if (prevState.query !== state.query) {
					setInstantSearchUiState({
						query: state.query
					})
					if (prevState.isOpen && !state.isOpen) {
						if (onSearch) onSearch(state.query)
					}
				}
			},
			renderer: {createElement, Fragment, render},
			plugins
		})

		return () => autocompleteInstance.destroy()
	}, [plugins, onSearch])

	return <div className={className} ref={autocompleteContainer} />
}
