import {Configure, InstantSearch} from 'react-instantsearch-hooks-web'
import {LangKey, LocaleContextProvider} from './locale/LocaleContext'
import algoliasearch, {SearchClient} from 'algoliasearch/lite'
import {createContext, useContext, useMemo} from 'react'

import {CMSAiresearchChannel} from 'cms-types'
import {GeneralData} from 'server/general'
import {InsightsMiddleware} from 'layout/search/InsightsMiddleware'
import {history} from 'instantsearch.js/es/lib/routers/index.js'
import {useRouter} from 'next/router'

export type AppEnvType = 'production' | 'staging' | 'development'

export type AppContextType = {
	general: GeneralData
	node: CMSAiresearchChannel
	lang: LangKey
	app_env: AppEnvType
	searchClient: SearchClient
}

const AppContext = createContext<AppContextType | false>(false)

const algoliaClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
	process.env.NEXT_PUBLIC_ALGOLIA_API_KEY
)

const searchClient: typeof algoliaClient = {
	...algoliaClient,
	search(requests) {
		if (requests.every(({params}) => !params.query)) {
			return Promise.resolve({
				results: requests.map(() => ({
					hits: [],
					nbHits: 0,
					nbPages: 0,
					page: 0,
					processingTimeMS: 0,
					hitsPerPage: 0,
					exhaustiveNbHits: false,
					query: '',
					params: ''
				}))
			})
		}

		return algoliaClient.search(requests)
	}
}

export const AppContextProvider: React.FC<
	Omit<AppContextType, 'searchClient'>
> = ({children, ...props}) => {
	const routing = useMemo(() => {
		const index = `pages_${props.lang}`
		return {
			router: history({
				writeDelay: 800
			}),
			stateMapping: {
				stateToRoute(uiState) {
					//TODO: when typing more than 3 chars in searchbox this function seems to get called in a infinite loop - enable console log below to see and fix
					// console.log('State to route', uiState)
					return {
						q: uiState[index].query
					}
				},
				routeToState(routeState) {
					console.log('routeToState', routeState)
					return {
						[index]: {
							query: routeState.q
						}
					}
				}
			}
		}
	}, [props.lang])

	return (
		<AppContext.Provider value={{...props, searchClient}}>
			<LocaleContextProvider lang={props.lang}>
				<InstantSearch
					searchClient={searchClient}
					indexName={`pages_${props.lang}`}
					routing={routing}
				>
					<InsightsMiddleware />
					{children}
				</InstantSearch>
			</LocaleContextProvider>
		</AppContext.Provider>
	)
}

export const useApp = () => {
	const result = useContext(AppContext)
	if (!result)
		throw new Error(
			'Trying to use the useApp hook, but no AppContext is defined.'
		)
	return result
}
