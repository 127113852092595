import 'react-responsive-modal/styles.css'

import React from 'react'
import {Modal as ReactModal} from 'react-responsive-modal'

const modalStyles = {
	background: '#36337d',
	boxShadow: 'none',
	color: 'white',
	margin: 0,
	padding: 0,
	width: '100%',
	height: '100%',
	maxWidth: '100%'
}

export const Modal: React.FC<{
	open: boolean
	onClose: () => void
}> = ({children, open, onClose}) => {
	return (
		<ReactModal
			open={open}
			onClose={onClose}
			center
			styles={{
				modal: modalStyles
			}}
			focusTrapped={false}
			showCloseIcon={false}
		>
			{children}
		</ReactModal>
	)
}
