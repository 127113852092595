import {useApp} from 'AppContext'
import {CMSLink} from 'cms-types'
import {Container} from 'layout/Container'
import {Icon} from 'layout/Icon'
import {useTranslation} from 'locale/LocaleContext'
import NextImage from 'next/image'
import {useRouter} from 'next/router'
import React, {useState} from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import {HeaderType} from './Header'
import css from './Mobilenav.module.scss'

const styles = fromModule(css)

export const Mobilenav: React.FC<
	HeaderType & {
		onClose: () => void
	}
> = ({onClose, nav, subnav, lang}) => {
	return (
		<nav className={styles.mobilenav()}>
			<Container>
				<MobilenavTop onClose={onClose} />
				<MobilenavMenu
					onClose={onClose}
					nav={nav}
					subnav={subnav}
					lang={lang}
				/>
			</Container>
		</nav>
	)
}

const MobilenavTop: React.FC<{onClose: () => void}> = ({onClose}) => {
	const t = useTranslation()
	const {lang} = useApp()

	return (
		<div className={styles.top()}>
			<div className={styles.top.left()}>
				<Link
					to={lang ? `/${lang}` : '/'}
					onClick={onClose}
					className={styles.top.left.logo()}
				>
					<NextImage
						priority
						src={'/logo-ai-white.svg'}
						alt={`Logo ${t.projectname}`}
						width="46.5"
						height="50"
					/>
				</Link>
			</div>
			<div className={styles.top.right()}>
				<a onClick={onClose} className={styles.top.right.hamburger()} />
			</div>
		</div>
	)
}

const MobilenavMenu: React.FC<HeaderType & {onClose: () => void}> = ({
	onClose,
	nav,
	subnav,
	lang
}) => {
	const router = useRouter()
	const [activeItem, setItem] = useState<any | null>(null)

	return (
		<nav className={styles.menu()}>
			{!activeItem && (
				<>
					{nav?.length > 0 &&
						nav.map((item, i) => (
							<div key={i} className={styles.menu.item()}>
								<Link
									key={i}
									to={item.has_dropdown ? null : item.link.url}
									onClick={item.has_dropdown ? () => setItem(item) : onClose}
									className={styles.menu.item.link.is({
										active: router.asPath === item.link.url
									})()}
								>
									<span className={styles.menu.item.link.label()}>
										{item.link.description}
									</span>
									{item.has_dropdown && (
										<span className={styles.menu.item.link.icon()}>
											<Icon icon="chevron_right" />
										</span>
									)}
								</Link>
							</div>
						))}
					{subnav?.length > 0 &&
						subnav.map((item, i) => (
							<div className={styles.menu.item()}>
								<Link
									key={i}
									to={item.has_dropdown ? null : item.link.url}
									onClick={item.has_dropdown ? () => setItem(item) : onClose}
									className={styles.menu.item.link.is({
										active: router.asPath === item.link.url
									})()}
								>
									<span className={styles.menu.item.link.label()}>
										{item.link.description}
									</span>
									{item.has_dropdown && (
										<span className={styles.menu.item.link.icon()}>
											<Icon icon="chevron_right" />
										</span>
									)}
								</Link>
							</div>
						))}
					<div className={styles.menu.item()}>
						<Link
							to={lang === 'en' ? `/${lang}` : `/en`}
							onClick={onClose}
							className={styles.menu.item.link()}
						>
							{lang === 'en' ? 'NL' : 'EN'}
						</Link>
					</div>
				</>
			)}
			{activeItem && (
				<MobilenavActive
					{...activeItem}
					onBack={() => setItem(null)}
					onClose={onClose}
				/>
			)}
		</nav>
	)
}

const MobilenavActive: React.FC<{
	link: CMSLink
	has_dropdown?: boolean
	sublinks?: CMSLink[]
	onBack: () => void
	onClose: () => void
}> = ({link, has_dropdown, sublinks, onBack, onClose}) => {
	const t = useTranslation()
	const router = useRouter()

	return (
		<div className={styles.active()}>
			<div className={styles.menu.item()}>
				<Link onClick={onBack} className={styles.menu.item.link.mod('back')()}>
					<span className={styles.menu.item.link.icon()}>
						<Icon icon="chevron_left" />
					</span>
					<span className={styles.menu.item.link.label()}>{t.back}</span>
				</Link>
			</div>
			<div className={styles.menu.item()}>
				<Link
					to={link.url}
					onClick={onClose}
					className={styles.menu.item.link.is({
						active: router.asPath === link.url
					})()}
				>
					{link.description}
				</Link>
			</div>
			{has_dropdown && sublinks?.length > 0 && (
				<div className={styles.menu.item()}>
					{sublinks.map(
						(sublink) =>
							sublink?.url && (
								<div key={sublink.url} className={styles.menu.item()}>
									<Link
										to={sublink.url}
										onClick={onClose}
										className={styles.menu.item.link.is({
											active: router.asPath === sublink.url
										})()}
									>
										{sublink.description}
									</Link>
								</div>
							)
					)}
				</div>
			)}
		</div>
	)
}
