import React, {useEffect, useMemo, useState} from 'react'

import {Autocomplete} from 'layout/search/Autocomplete'
import {CMSLink} from 'cms-types'
import {Container} from 'layout/Container'
import {Link} from 'util/link'
import {Mobilenav} from 'layout/header/Mobilenav'
import {Modal} from 'layout/Modal'
import NextImage from 'next/image'
import css from './Header.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'
import {useMediaQuery} from 'util/mediaquery'
import {useRouter} from 'next/router'
import {useTranslation} from 'locale/LocaleContext'

const styles = fromModule(css)

export type HeaderNavType = {
	link: CMSLink
	has_dropdown?: boolean
	sublinks?: CMSLink[]
}[]

export type HeaderType = {
	nav: HeaderNavType
	subnav?: HeaderNavType
	lang?: string
}

export const Header: React.FC = () => {
	const {general, lang, node} = useApp()
	const {nav, subnav} = general.header
	const isHome = node?.channel === 'home'
	const t = useTranslation()
	const router = useRouter()

	const [fixed, setFixed] = useState(false)

	useEffect(() => {
		const fixedBackground = () => {
			const isFixed = window.scrollY > 0
			if (fixed !== isFixed) setFixed(isFixed)
		}
		fixedBackground()
		window.addEventListener('scroll', fixedBackground)
		return () => window.removeEventListener('scroll', fixedBackground)
	}, [fixed, setFixed])

	const onSearch = useMemo(() => {
		return (query: string) => {
			;(window as any).location =
				`/${lang}/search?q=` + encodeURIComponent(query)
		}
	}, [lang])

	return (
		<div className={styles.header.mod({home: isHome}).is({fixed})()}>
			<Container className={styles.header.inner()}>
				<div className={styles.header.row()}>
					<div className={styles.header.row.left()}>
						<Link
							to={lang ? `/${lang}` : '/'}
							title={t.projectname}
							className={styles.header.logo()}
						>
							<NextImage
								priority
								src={isHome ? '/logo-ai-white.svg' : '/logo-ai-color.svg'}
								alt={`Logo ${t.projectname}`}
								width="46.5"
								height="50"
							/>
							{isHome && (
								<p className={styles.header.logo.projectname()}>
									{t.projectname}
								</p>
							)}
						</Link>
						<HeaderNav nav={nav} />
						{router.pathname !== `/[lang]/search` && (
							<Autocomplete
								className={styles.autocomplete()}
								onSearch={onSearch}
								placeholder="Search"
								detachedMediaQuery="none"
							/>
						)}
					</div>
					<div className={styles.header.row.right()}>
						<HeaderMobile nav={nav} subnav={subnav} lang={lang} />
						<HeaderNav nav={subnav} />
						<HeaderTranslation />
					</div>
				</div>
			</Container>
		</div>
	)
}

const HeaderMobile: React.FC<HeaderType> = ({nav, subnav, lang}) => {
	const isDesktop = useMediaQuery('(min-width: 1200px)')
	const [openMobilenav, setMobilenav] = useState<boolean>(false)
	if (isDesktop) return null

	return (
		<div className={styles.mobile()}>
			<a
				onClick={() => setMobilenav(!openMobilenav)}
				className={styles.mobile.hamburger()}
			/>
			<Modal open={openMobilenav} onClose={() => setMobilenav(false)}>
				<Mobilenav
					onClose={() => setMobilenav(false)}
					nav={nav}
					subnav={subnav}
					lang={lang}
				/>
			</Modal>
		</div>
	)
}

const HeaderNav: React.FC<{nav: HeaderNavType}> = ({nav}) => {
	const router = useRouter()
	if (nav?.length === 0) return null

	return (
		<nav className={styles.nav()}>
			{nav.map(
				(item, i) =>
					item?.link?.url && (
						<div
							key={i}
							className={styles.nav.item.mod({dropdown: item.has_dropdown})()}
						>
							<Link
								to={item.link.url}
								className={styles.nav.item.link.is({
									active: router?.asPath === item.link.url
								})()}
							>
								{item.link.description}
							</Link>
							{item.has_dropdown && item.sublinks?.length > 0 && (
								<div className={styles.nav.item.dropdown()}>
									{item.sublinks.map(
										(sublink) =>
											sublink?.url && (
												<Link
													to={sublink.url}
													key={sublink.url}
													className={styles.nav.item.dropdown.link()}
												>
													{sublink.description}
												</Link>
											)
									)}
								</div>
							)}
						</div>
					)
			)}
		</nav>
	)
}

const HeaderTranslation: React.FC = () => {
	const {lang, node} = useApp()
	const translateLang = lang === 'en' ? 'nl' : 'en'

	return (
		<div className={styles.translation()}>
			<a
				href={
					node && node.translations && node.translations[translateLang]
						? node.translations[translateLang]?.url
						: lang === 'en'
						? `/nl`
						: `/en`
				}
				target="_self"
				className={styles.translation.link()}
			>
				{lang === 'en' ? 'NL' : 'EN'}
			</a>
		</div>
	)
}
