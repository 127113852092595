import {useApp} from 'AppContext'
import {Container} from 'layout/Container'
import {useTranslation} from 'locale/LocaleContext'
import Image from 'next/image'
import React from 'react'
import {Link} from 'util/link'
import {fromModule} from 'util/styler/Styler'
import css from './Footer.module.scss'

const styles = fromModule(css)

export const Footer: React.FC = () => {
	const {general, lang} = useApp()
	const {links} = general.footer
	const t = useTranslation()

	return (
		<div className={styles.footer()}>
			<Container>
				<div className={styles.footer.row()}>
					<Link
						to={lang ? `/${lang}` : '/'}
						title={t.projectname}
						className={styles.footer.logo()}
					>
						<div className={styles.footer.logo.img()}>
							<Image
								src="/logo-ai-color.svg"
								alt={`Logo ${t.projectname}`}
								width="46.5"
								height="50"
							/>
						</div>
						<p className={styles.footer.logo.text()}>{t.projectname}</p>
					</Link>
					{links?.length > 0 && (
						<nav className={styles.footer.nav()}>
							{links.map((link, i) => (
								<Link
									to={link.url}
									key={i}
									className={styles.footer.nav.link()}
								>
									{link.description}
								</Link>
							))}
						</nav>
					)}
				</div>
			</Container>
		</div>
	)
}
