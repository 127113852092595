import {Footer} from './footer/Footer'
import {Header} from './header/Header'
import React from 'react'
import css from './Layout.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useApp} from 'AppContext'

const styles = fromModule(css)

export const Layout: React.FC = ({children}) => {
	const {node} = useApp()
	const isHome = node?.channel === 'home'

	return (
		<div className={styles.layout()}>
			<div className={styles.layout.top()}>
				<Header />
				<div className={styles.layout.content.mod({home: isHome})()}>
					{children}
				</div>
			</div>
			<Footer />
		</div>
	)
}
