import {AppEnvType, useApp} from 'AppContext'
import React, {useEffect, useState} from 'react'

import {CMSAiresearchChannel} from 'cms-types'
import css from './AdminTools.module.scss'
import {fromModule} from 'util/styler/Styler'
import {useMediaQuery} from 'util/mediaquery'
import {useRouter} from 'next/router'

const styles = fromModule(css)

export const useIsAdmin = (app_env: AppEnvType) => {
	const [isAdmin, setIsAdmin] = useState(false)

	useEffect(() => {
		if (app_env === 'development') {
			setIsAdmin(true)
			return
		}

		if (!localStorage.admin_enabled) return
		if (!localStorage.admin_refreshed) return

		const validUntil =
			parseInt(localStorage.admin_refreshed, 10) + 1000 * 60 * 60 * 24 * 14
		if (Date.now() > validUntil) return

		setIsAdmin(true)
	}, [])

	return isAdmin
}

export const AdminTools: React.FC<{node: CMSAiresearchChannel}> = ({node}) => {
	const {app_env} = useApp()
	const isAdmin = useIsAdmin(app_env)
	const router = useRouter()
	const isDesktop = useMediaQuery('(min-width: 1200px)')

	if (!isAdmin) return null
	if (!node) return null
	if (router.isPreview) return null
	if (!isDesktop) return null

	const editUrl =
		node?.id && node?.language && node?.project_key
			? `${process.env.NEXT_PUBLIC_ADMIN_URL}/project/${node.project_key}/${node.language}/entry/${node.id}`
			: process.env.NEXT_PUBLIC_ADMIN_URL

	return (
		<div className={styles.tools()}>
			<a
				href={editUrl}
				target="_blank"
				rel="noreferrer"
				className={styles.tools.button()}
			>
				Edit
			</a>
			{app_env === 'development' && (
				<a
					href={`/api/data${node.url}`}
					target="_blank"
					rel="noreferrer"
					className={styles.tools.button()}
				>
					JSON
				</a>
			)}
		</div>
	)
}
