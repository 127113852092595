import dateLocale from 'date-fns/locale/nl-BE'

export const nl = {
	dateLocale,
	projectname: 'Vlaams AI-Onderzoeksprogramma',
	back: 'terug',
	knowmore: 'Meer weten?',
	search_noresults: 'Geen resultaten gevonden.',
	loadmore: 'Laad meer',
	readmore: 'Lees meer over',
	domains: {
		description:
			'Binnen dit programma focussen we op volgende toepassingsdomeinen'
	},
	events: {
		past_events: 'Afgelopen evenementen',
		empty_events: 'Momenteel zijn er geen events beschikbaar.'
	},
	news: {
		overview: 'Overzicht'
	},
	library: {
		date: 'Datum publicatie',
		description: 'Titel / omschrijving',
		link: 'Link',
		empty: 'Er zijn geen resultaten gevonden.'
	},
	toolbox: {
		title: 'Titel',
		description: 'Omschrijving',
		categories: 'Categorieën',
		link: 'Link',
		download: 'Download',
		empty:
			'Er zijn geen resultaten gevonden. Probeer je filteropties aan te passen.',
		clear: 'Filters wissen'
	},
	team: {
		email: 'E-mail'
	},
	contact: {
		fname: 'Voornaam*',
		lname: 'Achternaam*',
		email: 'E-mailadres*',
		research: 'Ik heb een vraag of bericht over:',
		domain: 'Mijn vraag gaat over het volgende toepassingsdomein:',
		question: 'Vul hier uw vraag in:',
		newsletter: 'Ik wil graag op de hoogte blijven via de nieuwsbrief.',
		agreement: 'Ik ga akkoord met het privacybeleid.*',
		submit: 'Contacteer ons',
		thankyou: '<h2>Bedankt!</h2><p>Uw inzending is succesvol verzonden.</p>'
	},
	social: {
		linkedin: {
			description: `Volg ons op\nLinkedIn`,
			url: 'https://be.linkedin.com/company/flanders-ai-research-program'
		}
	},
	share: 'Deel op',
	page404: {
		title: 'Pagina niet gevonden',
		text: 'De pagina die je probeert te bereiken is niet beschikbaar of is onlangs verwijderd.',
		btn: 'Ga naar startpagina'
	}
}
