import 'styles/global.scss'
import 'styles/block.scss'
import 'instantsearch.css/themes/satellite.css'
import 'styles/algolia.scss'

import {AppContextProvider, AppEnvType} from 'AppContext'
import {CMSAiresearchChannel, CMSChannelMeta, CMSPageChannel} from 'cms-types'

import {AdminTools} from 'layout/admin/AdminTools'
import type {AppProps} from 'next/app'
import {GeneralData} from 'server/general'
import Head from 'next/head'
import {Layout} from 'layout/Layout'
import TagManager from 'react-gtm-module'
import {useEffect} from 'react'
import {useTranslation} from 'locale/LocaleContext'

function _app(
	props: AppProps<{
		lang?: 'nl' | 'en'
		general: any
		app_env: AppEnvType
		node: CMSPageChannel
	}>
) {
	const t = useTranslation()
	const {Component, pageProps} = props
	const app_env = pageProps.app_env
	const general: GeneralData = pageProps.general
	const node: CMSAiresearchChannel = pageProps.node
	const meta: CMSChannelMeta = node?.metadata
	const lang = pageProps.lang || (node?.language as 'nl' | 'en') || 'nl'

	useEffect(() => {
		document.documentElement.lang = lang

		if (process.env.NEXT_PUBLIC_GTM_ID)
			TagManager.initialize({gtmId: process.env.NEXT_PUBLIC_GTM_ID})
	}, [lang])

	return (
		<>
			<Head>
				<meta charSet="utf-8" />
				{app_env === 'production' ? (
					<meta name="robots" content="index, follow" />
				) : (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<meta name="viewport" content="initial-scale=1.0, width=device-width" />
				<meta name="language" content={lang} />
				<link rel="shortcut icon" href="/favicon.png" />
				{(meta || node) && (
					<>
						<title>
							{meta?.title && meta?.title !== node.title
								? meta.title
								: node.title + ' - ' + t.projectname}
						</title>
						<meta property="og:type" content="website" />
						<meta
							property="og:url"
							content={process.env.NEXT_PUBLIC_APP_URL + node.url}
						/>
						<meta
							property="og:title"
							content={meta?.og?.title || meta?.title || node.title}
						/>
					</>
				)}
				{meta?.description && (
					<>
						<meta name="description" content={meta.description} />
						<meta
							property="og:description"
							content={meta.og?.description || meta.description}
						/>
					</>
				)}
				{meta?.image && (
					<>
						<meta
							property="og:image"
							content={process.env.NEXT_PUBLIC_APP_URL + meta.image.src}
						/>
						<meta property="og:image:width" content={'' + meta.image.width} />
						<meta property="og:image:height" content={'' + meta.image.height} />
					</>
				)}
			</Head>
			<AppContextProvider
				general={general}
				node={node}
				lang={lang}
				app_env={app_env}
			>
				<Layout>
					{node && <AdminTools node={node} />}
					{node && <Component {...node} />}
					{!node && <Component />}
				</Layout>
			</AppContextProvider>
		</>
	)
}

export default _app
