import React, {HTMLProps} from 'react'
import {fromModule} from 'util/styler/Styler'
import css from './Container.module.scss'

const styles = fromModule(css)

export const Container: React.FC<HTMLProps<HTMLDivElement>> = ({
	children,
	...props
}) => <div className={styles.container.mergeProps(props)()}>{children}</div>
