import dateLocale from 'date-fns/locale/en-US'
import {nl} from './nl'

export const en: typeof nl = {
	dateLocale,
	projectname: 'Flanders AI Research Program',
	back: 'back',
	knowmore: 'Want to know more?',
	loadmore: 'Load more',
	readmore: 'Read more about',
	search_noresults: 'No search results.',
	domains: {
		description:
			'Within this program we focus on the following application domains'
	},
	events: {
		past_events: 'Past events',
		empty_events: 'There are currently no events available.'
	},
	news: {
		overview: 'Overview'
	},
	library: {
		date: 'Publication date',
		description: 'Title / description',
		link: 'Link',
		empty: 'No results were found.'
	},
	toolbox: {
		title: 'Title',
		description: 'Description',
		categories: 'Categories',
		link: 'Link',
		download: 'Download',
		empty: 'No results were found. Try adjusting your filter options.',
		clear: 'Clear filters'
	},
	team: {
		email: 'Email'
	},
	contact: {
		fname: 'First name*',
		lname: 'Last name*',
		email: 'Email address*',
		research: 'I have a question or message about:',
		domain: 'My question is about the following application domain:',
		question: 'Enter your question here:',
		newsletter: 'I would like receive the newsletter to stay informed.',
		agreement: 'I agree with the privacy policy.*',
		submit: 'Contact us',
		thankyou:
			'<h2>Thank you!</h2><p>Your submission has been successfully sent.</p>'
	},
	social: {
		linkedin: {
			description: `Follow us on\nLinkedIn`,
			url: 'https://be.linkedin.com/company/flanders-ai-research-program'
		}
	},
	share: 'Share on',
	page404: {
		title: 'Page not found',
		text: `The page you are looking for might have been removed, had its name 
			changed, or is temporarily unavailable.`,
		btn: 'Back to home'
	}
}
